import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "users-"
    }}>{`Users 👩‍🦰🧓👨🏾‍🦰`}</h1>
    <p>{`There are 3 types of users in Canyou:`}</p>
    <p><strong parentName="p">{`Admin`}</strong>{` - Full privileges on the platform within the businesses they are members of.`}</p>
    <p><strong parentName="p">{`Supervisor`}</strong>{` - Limited privileges, as follows:`}</p>
    <ul>
      <li parentName="ul">{`Cannot access the `}<strong parentName="li">{`Settings`}</strong>{` menu`}</li>
      <li parentName="ul">{`Cannot invite `}<strong parentName="li">{`admin`}</strong>{` users`}</li>
      <li parentName="ul">{`Cannot access the `}<strong parentName="li">{`User Documents`}</strong>{` section within a users profile`}</li>
      <li parentName="ul">{`Cannot `}<strong parentName="li">{`Register a business`}</strong></li>
      <li parentName="ul">{`Cannot view workflows completed by other `}<strong parentName="li">{`members`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Member`}</strong>{` - Can only access the Workflows that they have been assigned.`}</p>
    <p>{`The following guides show to add each type of user`}</p>

    <SupportFooter linkTo="/support/users/invite-a-member" linkText="Invite a member" mdxType="SupportFooter" />
    <SupportFooter linkTo="/support/users/invite-a-supervisor" linkText="Invite a supervisor" mdxType="SupportFooter" />
    <SupportFooter linkTo="/support/users/invite-an-admin" linkText="Invite an admin" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      